// src/@chakra-ui/gatsby-plugin/theme.js
import {extendTheme} from '@chakra-ui/react';
const theme = {
  colors: {
    primary: 'green',
    myGreen: '#18FF84'
  },
  fonts: {
    heading: 'rig-solid-bold-inline-solo, sans-serif',
    body: "'museo-sans', sans-serif"
  }
};

export default extendTheme(theme);
